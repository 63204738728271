  <template>
    <div>
       <vx-card
       style="font-size: 18px;font-family:almarai ; padding:15px ;"
        ref="filterCard"
        :title="$t('Filters')"

        collapse-action
      >
        <div class="vx-row">
          <template v-for="field in filterFields">
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4" :key="field.name">
              <label class="text-sm opacity-75">{{ $t(field.label) }}</label>
              <vs-input
                v-if="!field.isDropdown"
                v-model="search[field.model]"
                class="w-full"
                :name="field.name"
              />

            </div>
          </template>





          <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
            <!-- <vs-button
              class="my-6 rounded-sm"
              color="warning"
              @click="handleSearch"
              >{{ $t("Search") }}</vs-button
            > -->
            <vs-button  style="margin-top: 30px" color="primary" type="border" icon-pack="feather" icon="icon-search"  @click="handleSearch">
              {{ $t("Search") }}
            </vs-button>
          </div>
        </div>
      </vx-card>

      <div id="data-list-list-view" class="data-list-container">
        <vs-table
          ref="table"
          id="Scroll"
          pagination
          :max-items="itemsPerPage"
          search
          :data="AdsManagements"
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >

          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ AdsManagements.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : AdsManagements.length }} of {{ AdsManagements.length }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <template slot="thead">
            <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{ $t("Name") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{ $t("AdsCatgeory") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="Name">{{ $t("Address") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color: green;" sort-key="PhoneNumber">{{ $t("PhoneNumber") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color:crimson;" sort-key="Status">{{ $t("Status") }}</vs-th>
            <vs-th style="font-size: 16px;font-family:almarai ; color:crimson;"> {{ $t("Action") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <tr
                :data="tr"
                :key="indextr"
                v-for="(tr, indextr) in data"
                @click="editData(tr)"
              >
                <vs-td>
                  <p style="font-size: 16px;font-family:almarai ;">
                    {{ tr.Customer.NameAR }}
                  </p>
                </vs-td>
                <vs-td>
                  <p style="font-size: 16px;font-family:almarai ;">
                    {{ tr.Category.NameAr }}
                  </p>
                </vs-td>
                <vs-td>
                  <p style="font-size: 16px;font-family:almarai ;">
                    {{ tr.AddressAR }}
                  </p>
                </vs-td>

                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.Customer.Phone }}
                  </p>

                </vs-td>



                <template>
                  <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.PublishStatus.NameAr }}
                  </p>

                </vs-td>
</template>


                <vs-td class="whitespace-no-wrap">
                  <router-link
                    :to="{
                      name: 'AdDetails',
                      params: { ID: tr.ID },
                    }"
                  >

                    <feather-icon
                      icon="EditIcon"
                      @click.stop="AddOrEditData(tr)"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>

                  <feather-icon
                    icon="TrashIcon"
                    svgClasses="w-10 h-5 hover:text-primary stroke-current"
                    @click.stop="openConfirm(tr)"
                  />
                </vs-td>

              </tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </template>

  <script>
  import { domain } from "@/gloabelConstant.js";
  import moduleAdsManagement from "@/store/AdsManagement/moduleAdsManagement.js";


  export default {
    components: {

    },
    data() {
      return {
        itemsPerPage: 10,
        search: {
          name: "",
          phoneNumber: "",
          pageNumber: 0,
          pageSize: 20,
        },
        filterFields: [
          { label: "FullName", model: "name", name: "FullName" },
          { label: "PhoneNumber", model: "phoneNumber", name: "PhoneNumber" },
            ],

        baseURL: domain,
      };
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx;
        }
        return 0;
      },
      AdsManagements() {
        return this.$store.state.AdsManagementList.AdsManagements;
      },
    },

    methods: {

    //   handleSearch() {
    //    debugger;
    //    this.$store
    //      .dispatch("AdsManagementList/SearchUnitAd", this.search)
    //      .then((res) => {
    //        debugger;
    //        this.AdsManagementdata = res.data.data;
    //      });
    //  },
      AddOrEditData(data) {
        debugger;
        if(data.ID == undefined){
          data.ID = 0;
        }
        this.$router.push(`/AdsManagement/AdDetails/${data.ID}`);
      },
      AcceptRequests() {
      this.$router.push('/AdsManagement/AcceptRequests/');
  },

      acceptAlertBlock() {
        this.BlockOrUnBlockUser(this.rowDataForDelete);
        this.$vs.notify({
          color: "success",
          title: "Deleted record",
          text: this.$t("DeletedSuccessfully"),
        });

      },
      cancelsuccess() {
        this.$vs.notify({
          color: "success",
          title: "Successfuly UnBlocked",

        });
      },

      approvedsuccess() {
        this.$vs.notify({
          color: "success",
          title: "تم تغير الحالة",

        });
      },
      openConfirm(data) {
      debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },
    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });
    },
      deleteData(data) {
        debugger;
        this.$store
          .dispatch("AdsManagementList/DeleteUnitAd", data)

          .catch((err) => {
            console.error(err);
          });
      },


    },
    created() {
      if (!moduleAdsManagement.isRegistered) {
        this.$store.registerModule("AdsManagementList",moduleAdsManagement
        );
        moduleAdsManagement.isRegistered = true;
      }

      this.$store
          .dispatch("AdsManagementList/fetchDataListItems");


    },
    mounted() {
      this.isMounted = true;

    },
  };
  </script>

  <style lang="scss">
 /* Base Button Styles */
button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  outline: none;
  color: white; /* White text for active and blocked button */
}

/* Active (Green) Button */
.btn-active {
  background: linear-gradient(135deg, #4caf50, #4caf50); /* Green color */
}

.btn-active:hover {
  background: linear-gradient(135deg, #2e7d32, #4caf50); /* Darker green for hover */
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(76, 175, 80, 0.4);
}

.btn-active:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(76, 175, 80, 0.2);
}

/* Blocked (Red) Button */
.btn-blocked {
  background: linear-gradient(135deg, #ff5f5f, #ff5f5f); /* Red color */
}

.btn-blocked:hover {
  background: linear-gradient(135deg, #ff2d2d, #ff2d2d); /* Darker red for hover */
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(255, 93, 93, 0.4);
}

.btn-blocked:active {
  transform: translateY(0);
  box-shadow: 0 4px 8px rgba(255, 93, 93, 0.2);
}

/* Pending (Orange) Button */
.btn-pending {
  background: linear-gradient(135deg, #ff9800, #ff9800); /* Orange color */
  color: white; /* Text color for pending status */
  cursor: not-allowed;
}

.btn-pending:hover {
  background: linear-gradient(135deg, #ff5722, #ff9800); /* Darker orange for hover */
}

  #data-list-list-view small {
    color: rgb(255, 115, 0);
    text-decoration: underline rgb(255, 115, 0);
  }
  .toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #28C76F;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #FF6347;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}
.vxCard:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0%;
    margin-left: 10%;
    z-index: 500000;
    width: 25%;
    height: 20%;
    border-top: 3px solid rgb(255, 115, 0) !important;
    border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
    border-right: 0 none rgba(255, 0, 0, 0.9) !important;
    border-left: 0 none rgba(0, 0, 0, 0.9) !important;
  }
  #data-list-list-view {
    .vs-con-table {
      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
          td {
            padding: 20px;
            &:first-child {
              border-top-left-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
            }
            &:last-child {
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }
          }
          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check {
          padding: 0 15px !important;
        }
        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
